import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar/snack-bar.service';
import { ConfirmationDialogData } from '@campaign/components/campaign-detail/confirmation-dialog/confirmation-dialog.data';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs';
import { DeleteUser, Disable2FA, Enable2FA, UpdateUser } from '../store/user.actions';
import { UserEndpointService } from './user.endpoint.service';
import { ConfirmationDialogComponent } from '@campaign/components/campaign-detail/confirmation-dialog/confirmation-dialog.component';
import { UserDetailDialogComponent } from '@user-management/component/user-detail-dialog/user-detail-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private store = inject(Store);
  private dialog = inject(MatDialog);
  private _translateService = inject(TranslateService);
  public _userEndPointService = inject(UserEndpointService);
  private _snackBarService = inject(SnackBarService);

  public deleteUser(userId): Observable<any> {
    return this.dialog
      .open(ConfirmationDialogComponent, {
        data: new ConfirmationDialogData(
          this._translateService.instant('user.deleteUser'),
          this._translateService.instant('user.deleteUserQuestion'),
          this._translateService.instant('button.cancel'),
          this._translateService.instant('site.delete'),
        ),
        panelClass: 'full-width-dialog',
      })
      .beforeClosed()
      .pipe(
        take(1),
        filter((_) => !!_),
        switchMap((_) => this.store.dispatch(new DeleteUser(userId))),
      );
  }

  public toggle2FA(userId, twoFaEnabled): Observable<any> {
    return this.dialog
      .open(ConfirmationDialogComponent, {
        data: new ConfirmationDialogData(
          this._translateService.instant(twoFaEnabled ? 'disable2fa' : 'enable2fa'),
          this._translateService.instant(twoFaEnabled ? 'disable2faQuestion' : 'enable2faQuestion'),
          this._translateService.instant('button.cancel'),
          this._translateService.instant('button.submit'),
        ),
        panelClass: 'full-width-dialog',
      })
      .beforeClosed()
      .pipe(
        take(1),
        filter((_) => !!_),
        switchMap((_) => this.store.dispatch(twoFaEnabled ? new Disable2FA(userId) : new Enable2FA(userId))),
      );
  }

  public restoreUser(user) {
    this.store.dispatch(new UpdateUser({ ...user, isActive: true }));
  }

  public resetPassword(email, userId) {
    return this.dialog
      .open(ConfirmationDialogComponent, {
        data: new ConfirmationDialogData(
          this._translateService.instant('user.resetPassword'),
          this._translateService.instant('user.resetPasswordQuestion', {
            email: email,
          }),
          this._translateService.instant('button.cancel'),
          this._translateService.instant('button.continue'),
        ),
        panelClass: 'full-width-dialog',
      })
      .beforeClosed()
      .pipe(
        take(1),
        filter((_) => !!_),
        switchMap((_) => this._userEndPointService.sendPasswordEmail(userId).pipe(take(1))),
      )
      .subscribe((_) => {
        this._snackBarService.info(
          this._translateService.instant('user.passwordEmailSent', {
            email: email,
          }),
        );
      });
  }

  public openUserDetailDialog(id?: string, owner?: string) {
    const dialog = this.dialog.open(UserDetailDialogComponent, {
      maxHeight: '95vh',
      width: '95vw',
      data: { id, owner },
      panelClass: ['full-width-dialog'],
      disableClose: true,
    });
    dialog
      .beforeClosed()
      .pipe(take(1))
      .subscribe(() => {});
  }
}
