<div class="close-button">
  <button mat-icon-button mat-dialog-close tabindex="-1" id="btn_close">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<app-user-detail-page
  [id]="id"
  [owner]="owner"
  (closeDialogHandler)="closeDialog()"
  [openInDialog]="true"
/>
