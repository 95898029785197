<mat-form-field floatLabel="always" [class]="control.value?.length ? 'custom-multiselect' : ''">
  <mat-label>{{ 'user.roles' | translate }}</mat-label>
  <app-multiselect-autocomplete-component
    id="user-detail-select-role"
    [formControl]="control"
    [dropdownArray]="allRoles | sort: 'name'"
    [displayProperty]="'name'"
    [returnValue]="'id'"
    [required]="required"
    [showChipLoader]="isChipLoading"
    [customOptionTemplate]="getTemplate"
    [showMatError]="userRoleMatError"
  >
  </app-multiselect-autocomplete-component>
  <mat-error #userRoleMatError />
</mat-form-field>
