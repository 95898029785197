@if (!!form && !loading && !!owners?.length) {
  <h1 class="mat-h1 mr-4 mb-6">
    {{
      creationMode
        ? ('user.createUser' | translate)
        : (form?.get('firstName')?.value || '') + ' ' + (form?.get('lastName')?.value || '')
    }}
  </h1>
  <mat-card class="mat-elevation-z1 card-shadow adtrac-card-primary-border">
    <mat-card-content>
      <form [formGroup]="form">
        <div class="row stretch mb-5">
          <h2 class="mat-h2 mb-0">{{ 'user.userInformation' | translate }}</h2>
          @if (user?.isActive || !creationMode) {
            <section class="flex center gap">
              @if (user.mfaEnabled) {
                <mat-chip color="primary">
                  <div class="flex center gap">
                    <mat-icon>enhanced_encryption </mat-icon>&nbsp; {{ '2faEnabled' | translate }}
                  </div>
                </mat-chip>
              } @else {
                <mat-chip disabled>
                  <div class="flex center gap">
                    <mat-icon>no_encryption </mat-icon>&nbsp; {{ '2faDisabled' | translate }}
                  </div>
                </mat-chip>
              }
              <app-contextual-menu
                [icon]="'more_vert'"
                id="usr_cntxt_men"
                matTooltip="{{ 'button.options' | translate }}"
                matTooltipPosition="left"
              >
                @if (!creationMode) {
                  <button
                    mat-menu-item
                    id="usr_dtl_btn_restore_usr"
                    (click)="toggle2Fa()"
                    [attr.disabled]="!(isUserAdmin$ | async)"
                  >
                    <mat-icon>{{ user.mfaEnabled ? 'no_encryption' : 'enhanced_encryption' }} </mat-icon>
                    <span> {{ (user.mfaEnabled ? 'disable2fa' : 'enable2fa') | translate }}</span>
                  </button>
                  <button
                    mat-menu-item
                    type="button"
                    id="usr_dtl_btn_reset_pswd"
                    (click)="sendPasswordEmail()"
                    [attr.disabled]="
                      creationMode ||
                      !this.form?.get('email')?.value ||
                      !this.form?.get('email')?.valid ||
                      (!creationMode && !user?.isActive)
                    "
                  >
                    <mat-icon>lock_reset</mat-icon>
                    <span>{{ 'user.resetPassword' | translate }}</span>
                  </button>
                }
                @if (!user?.isActive && !creationMode) {
                  <button mat-menu-item id="usr_dtl_btn_restore_usr" (click)="restoreUser()">
                    <mat-icon>restore_from_trash</mat-icon>
                    <span> {{ 'user.restore' | translate }}</span>
                  </button>
                }
                @if (user?.isActive) {
                  <button
                    mat-menu-item
                    id="usr_dtl_btn_delete_usr"
                    (click)="deleteUser()"
                    [attr.disabled]="creationMode"
                  >
                    <mat-icon>delete_forever</mat-icon>
                    <span>{{ 'user.deleteUser' | translate }}</span>
                  </button>
                }
              </app-contextual-menu>
            </section>
          }
        </div>
        @if (!creationMode) {
          <small class="created-on mb-3">
            {{ 'user.createdOn' | translate }}: {{ user?.dateJoined | date: 'dd.MM.yyyy' }}
          </small>
        }
        <div class="row">
          <mat-form-field class="third-width">
            <mat-label>{{ 'user.firstName' | translate }}</mat-label>
            <input
              formControlName="firstName"
              matInput
              id="usr_dtl_txt_first_name"
              type="text"
              name="firstName"
              required
              clearInput
              [showMatError]="firstNameMatError"
            />
            <mat-error #firstNameMatError />
          </mat-form-field>
          <mat-form-field class="third-width">
            <mat-label>{{ 'user.lastName' | translate }}</mat-label>
            <input
              formControlName="lastName"
              matInput
              id="usr_dtl_txt_last_name"
              type="text"
              name="lastName"
              required
              clearInput
              [showMatError]="lastNameMatError"
            />
            <mat-error #lastNameMatError />
          </mat-form-field>
          <mat-form-field class="third-width">
            <mat-label>{{ 'user.email' | translate }}</mat-label>
            <input
              formControlName="email"
              matInput
              id="usr_dtl_email"
              type="text"
              name="email"
              required
              clearInput
              appLowercaseOnType
              [showMatError]="emailMatError"
            />
            <mat-error #emailMatError />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="third-width">
            <mat-label>{{ 'layout.language' | translate }}</mat-label>
            <mat-select
              name="language"
              formControlName="language"
              required
              id="usr_dtl_select_language"
              [showMatError]="languageMatError"
            >
              @for (language of languages; track language) {
                <mat-option [value]="language">
                  {{ language }}
                </mat-option>
              }
            </mat-select>
            <mat-error #languageMatError />
          </mat-form-field>
          <app-autocomplete-selection
            [frmControl]="form.get('owner')"
            label="{{ 'user.owner' | translate }}"
            class="third-width owner-form-field"
            [id]="'usr_dtl_txt_owner'"
            ariaLabel="network"
            [keys]="['name']"
            value="id"
            required
            [list]="owners"
            (emitClearInput)="ownerSelected()"
            (emitOptionSelection)="ownerSelected()"
          >
            @if (!creationMode && !!form?.get('owner').value) {
              <span matHint>
                <a class="company-link" [routerLink]="['/tenants/detail/' + form?.get('owner').value]">
                  {{ 'user.companyPage' | translate }} <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
              </span>
            }
          </app-autocomplete-selection>
          <app-user-role
            class="third-width"
            id="user-detail-select-role"
            formControlName="roles"
            [ownerValue]="form?.get('owner').value"
            [clearRoleEvent]="clearRoleSubject.asObservable()"
            required
          />
        </div>
      </form>
      <div class="action-wrapper">
        @if (creationMode) {
          <button
            class="mb-4 mt-4 submit-button"
            type="submit"
            id="usr_dtl_btn_create_user"
            mat-flat-button
            color="primary"
            (click)="submit()"
            appInvalidControlScroll
          >
            @if (!submitLoading) {
              <mat-icon>add</mat-icon>
            }
            {{ 'user.createUser' | translate }}
          </button>
        } @else {
          <button
            class="mb-4 mt-4 submit-button"
            type="submit"
            id="usr_dtl_btn_save_user"
            mat-flat-button
            color="primary"
            (click)="submit()"
            appInvalidControlScroll
          >
            @if (submitLoading) {
              <mat-icon>
                <mat-spinner diameter="25"> </mat-spinner>
              </mat-icon>
            }
            @if (!submitLoading) {
              <mat-icon>edit</mat-icon>
            }
            {{ 'user.saveUser' | translate }}
          </button>
        }
      </div>
    </mat-card-content>
  </mat-card>
} @else {
  <app-skeleton-loader [theme]="{ display: 'block', height: '4rem', width: '35%', marginBottom: '2rem' }">
  </app-skeleton-loader>
  <mat-card class="mat-elevation-z1 card-shadow adtrac-card-primary-border">
    <mat-card-content>
      <div class="row mb-5">
        <app-skeleton-loader
          [style.width]="'100%'"
          [theme]="{ display: 'block', height: '2.5rem', width: '40%', marginBottom: '2rem' }"
        />
        <div class="flex" [style.width]="'20%'">
          <app-skeleton-loader
            [style.width]="'100%'"
            [theme]="{ display: 'block', width: '100%', marginBottom: '2rem', height: '2.5rem', float: 'right' }"
          >
          </app-skeleton-loader>
          <app-skeleton-loader
            [style.width]="'100%'"
            [theme]="{ display: 'block', width: '60%', marginBottom: '2rem', height: '2.5rem', float: 'right' }"
          >
          </app-skeleton-loader>
        </div>
      </div>
      @for (i of [1, 2]; track i) {
        <div class="row">
          <app-skeleton-loader class="third-width" [theme]="{ display: 'block', height: '3rem', marginBottom: '2rem' }">
          </app-skeleton-loader>
          <app-skeleton-loader class="third-width" [theme]="{ display: 'block', height: '3rem', marginBottom: '2rem' }">
          </app-skeleton-loader>
          <app-skeleton-loader class="third-width" [theme]="{ display: 'block', height: '3rem', marginBottom: '2rem' }">
          </app-skeleton-loader>
        </div>
      }
      <div class="row justify-center mb-5 mt-3">
        <app-skeleton-loader
          class="third-width"
          [theme]="{ display: 'block', height: '3rem', marginTop: '3rem', width: '50%', margin: '0 auto' }"
        >
        </app-skeleton-loader>
      </div>
    </mat-card-content>
  </mat-card>
}
